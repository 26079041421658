.collections-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
  margin-top: 3em;
}
.collections-masonry-grid-column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

/* Style your items */
.collections-masonry-grid-column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.MuiCardHeader-content {
  span.MuiTypography-root {
    font-size: 1.2em;
  }
}
