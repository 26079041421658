.alphabet-grid-item {
  text-decoration: none;
  .MuiPaper-root {
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiAvatar-root {
      width: 60px;
      height: 60px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      opacity: .7;
      &:hover {
        box-shadow: none;
        opacity: .9;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
      h2 {
        text-transform: uppercase;
        font-size: 1.2em;
      }
    }  
  }
}

@media (min-width: 400px) {
  .alphabet-grid-item {
    .MuiPaper-root {
      min-height: 90px;
      .MuiAvatar-root {
        width: 70px;
        height: 70px;
      }
    }
  }
}

@media (min-width: 600px) {
  .alphabet-grid-item {
    .MuiPaper-root {
      min-height: 120px;
      .MuiAvatar-root {
        width: 100px;
        height: 100px;
        h2 {
          font-size: 2em;
        }
      }
    }
  }
}
