footer {
  background: #7c7c7c;
  height: 64px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    span {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
}