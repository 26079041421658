.MuiPaper-root {
  .MuiToolbar-root {
    padding: 0;
  }
}

.MuiToolbar-root {
  flex-wrap: wrap;
  .logo-icon {
    cursor: pointer;
    width: .75em;
    height: .75em;
    margin-right: 10px;
    margin-left: 15px;
    order: -1;
  }
  .logo-text {
    flex-grow: 1;
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    order: 0;
  }
  .word-search-bar {
    flex-grow: 1;
    margin: 10px 2rem;
    order: 2;
  }
}

@media (min-width: 600px) {
  .MuiToolbar-root {
    .logo-icon {
      margin-left: 20px;
      margin-right: 15px;
      width: 1.2em;
      height: 1.2em;
    }
    .logo-text {
      font-size: 1.5rem;
    }
    .word-search-bar {
      flex-grow: 0;
      margin-left: 0;
      margin-right: 1em;
      order: 1;
    }
  }
}
