.MuiToolbar-root {
  .dot-menu-icon {
    margin-right: 0;
    order: 1;
  }
}

.MuiDrawer-modal {
  .MuiDrawer-paperAnchorRight {
    height: auto;
  }
}

@media (min-width: 600px) {
  .MuiToolbar-root {
    .dot-menu-icon {
      margin-right: 10px;
      order: 2;
    }
  }
}
